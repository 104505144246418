@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("./fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
}

* {
  /* box-sizing: border-box; */
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  /* margin: 0px 100px; */
  margin: 0px;
  background-color: white;
}