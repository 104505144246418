.arrowDownHighlight {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #1c45e1;
  margin-right: 1rem;
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #86b9f6;
  margin-right: 1rem;
}