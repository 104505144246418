@import '../../../libs/styles/_colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;

  .container {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 30px;
    color: $content_blue;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;
    font-family: 'Quicksand';

    .logo {
      height: 100px;
      width: 100px;
      text-align: center;
      margin-bottom: 50px;
    }

    .rigthItems {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;

      .socialIcons {
        min-height: 45px;
        border-radius: 50px;
        background-color: #4472c4;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 600px;
        padding-top: 15px;


        .socialIcon {
          height: 30px;
          cursor: pointer;
        }
      }

      .menu {
        display: flex;
        flex-direction: row;
        height: 130px;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;

        .menuItem {
          margin: 0 16px;

          .target {
            width: 100px;
            height: 125px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5;
            background: #fff;
            color: $content_primary;
            cursor: pointer;

            .menuImage {
              max-height: 200px;
            }

            .menuLabel {
              min-height: 80px;
            }
          }
        }

        .loginButton {
          width: 160px;
          height: 54px;
          color: $content_blue;
          box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
          border: 1.5px solid;
          border-color: $content_blue;
          border-radius: 100px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.5;
          background: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    width: 100vw;
  }
}