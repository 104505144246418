@import '../../../libs/styles/_colors';

.content {
  display: flex;
  font-family: 'Quicksand';
  align-items: center;
  justify-content: space-between;


  .id {
    display: flex;
    align-items: center;
    width: 60px;
    overflow: hidden;
    font-size: 14px;
    justify-content: flex-end;
    min-height: 48px;
    margin-left: 24px
  }

  .nombre {
    @extend .id;
    width: 350px;
    font-size: 14px;
    justify-content: flex-start;
  }

  .valorMaximo {
    @extend .id;
    width: 40px;
  }

  .valor {
    @extend .id;
    width: 60px;
  }

  .ponderacion {
    @extend .id;
    width: 60px;
  }

  .dimension {
    @extend .id;
    width: 200px;
    justify-content: center;
  }


  .input {
    border-radius: 8px;
    border: 0.5px solid;
    height: 30px;
    border-color: $content_blue;
    margin-left: 24px
  }

  .inputId {
    @extend .input;
    width: 60px
  }

  .inputNombre {
    @extend .input;
    width: 350px
  }

  .inputValorMaximo {
    @extend .input;
    width: 60px
  }

  .inputValor {
    @extend .input;
    width: 60px
  }

  .inputPonderacion {
    @extend .input;
    width: 60px
  }

  .inputDimension {
    @extend .input;
    border: 0;
    width: 200px;
    height: 36px;

  }

  .icon {
    margin-left: 16px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      min-width: 100px;
      height: 24px;
      box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
      border: 1.5px solid;
      border-color: $content_blue;
      border-radius: 32px;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.5;
      border: 0;
      color: #fff;
      background: $content_blue;
      font-family: 'Quicksand';
    }

    .saveButton {
      color: $content_blue;
      background: white;
      border: 1px solid $content_blue;
    }
  }
}