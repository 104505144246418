@import '../../libs/styles/_colors';

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Quicksand';

  .logoutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-top: 50px;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 16px 32px 16px;
    width: 90%;

    .cancelButton {
      width: 180px;
      height: 48px;
      color: $content_blue;
      box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
      border: 1.5px solid;
      border-color: $content_blue;
      border-radius: 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5;
      background: #fff;
    }

    .logoutButton {
      @extend .cancelButton;
      border: 0;
      color: #fff;
      background: $content_blue;

    }
  }
}