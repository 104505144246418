@import '../../../../libs/styles/_colors';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 30px;
  color: #131C41;
  max-width: 1450px;
  margin: auto;
  font-family: 'Quicksand';
  justify-content: space-between;

  .title {
    font-size: 46px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0.1px;
    color: $content_blue;
  }

  .subtitle {
    font-size: 32px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: #131C41;
    margin: 48px 0 32px 0;
  }

  .description {
    line-height: 32px;
  }

  .content {
    margin-top: 48px;
    line-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .slide {
      width: 100%;
      object-fit: contain;
    }

    .mapContainer {
      display: flex;
      justify-content: center;
      width: 55%;



      .map {
        height: 700px;
      }
    }

    .bottomText {
      margin: 60px 0;
      text-align: left;
      width: 80%;
      align-self: center;
    }
  }



}