@import '../../../../libs/styles/_colors';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 30px;
  color: #131C41;
  max-width: 1450px;
  margin: auto;
  justify-content: space-between;

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: $content_blue;
    font-family: 'Quicksand';
    ;
  }

  .subtitle {
    font-size: 32px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: #131C41;
    font-family: 'Quicksand';

  }

  .introduction {
    margin-top: 24px;
    line-height: 32px;
    display: flex;
    justify-content: center;
  }

  .description {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    justify-content: center;
    padding: 0 200px;

    .text {}

    .image {
      margin-left: 24px;
    }

  }

  .mapContainer {
    margin: 100px 60px;
    display: flex;
    justify-content: center;

    .map {
      height: 700px;
    }
  }

  .bottomText {
    margin: 60px 0;
    text-align: center;
    width: 80%;
    align-self: center;
  }


}