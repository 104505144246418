@import '../../libs/styles/_colors';

.container {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  min-height: 600px;
  font-family: 'Quicksand';

  .text {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 200px 0 0 0;

    .button {
      width: 280px;
      height: 48px;
      box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
      border: 1.5px solid;
      border-color: $content_blue;
      border-radius: 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5;
      border: 0;
      color: #fff;
      background: $content_blue;
      font-family: 'Quicksand';
      margin-top: 32px;
    }
  }
}