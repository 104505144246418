@import '../../../../libs/styles/_colors';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 50px 30px;
  color: #131C41;
  max-width: 1450px;
  margin: auto;
  justify-content: space-between;
  font-family: 'Quicksand';

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: $content_blue;
    ;
  }

  .subtitle {
    font-size: 32px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: #131C41;
    font-family: 'Quicksand';
  }

  .content {
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-top: 48px;
    align-items: center;
    width: 75%;

    .introduction {
      line-height: 32px;
      text-align: right;
      width: 60%;
    }

    .image {
      margin: 0 48px 48px 48px;
      border-radius: 32px;
      max-width: 400px;
    }
  }
}