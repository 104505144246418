.headerContainer {
  display: flex;
  font-family: Quicksand;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  .header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    font-size: 12px;
    font-weight: 700;
    margin-left: 24px;
  }
}

.alternatingColor>div:nth-child(even) {
  background-color: #EBF5FB;
}