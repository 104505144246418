@import '../../../libs/styles/_colors';

.content {
  display: flex;
  font-family: Quicksand;
  align-items: center;
  justify-content: left;
  // min-width: 1000px;
  width: 100%;
  height: 40px;

  .id {
    width: 180px;
    text-align: center;
  }

  .label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 300px;
    overflow: hidden;
    font-size: 14px;
    justify-content: left;
    min-height: 24px;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 300px;
    overflow: hidden;
    font-size: 14px;
    justify-content: left;
    min-height: 24px;

    .editInput {
      border-radius: 8px;
      border: 0.5px solid;
      height: 24px;
      border-color: $content_blue;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon {
      margin-left: 16px;
    }

    .button {
      // min-width: 80px;
      height: 24px;
      box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
      border: 1.5px solid;
      border-color: $content_blue;
      border-radius: 32px;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.5;
      border: 0;
      color: #fff;
      background: $content_blue;
      font-family: 'Quicksand';
    }

    .saveButton {
      color: $content_blue;
      background: white;
      border: 1px solid $content_blue;
    }
  }
}