@import '../../libs/styles/_colors';

.headerContainer {
  display: flex;
  font-family: Quicksand;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  font-size: 12px;

  .header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 700;
    margin-right: 12px;
    min-width: 150px;
  }
}

.alternatingColor>div:nth-child(even) {
  background-color: #EBF5FB;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;

  .button {
    width: 180px;
    height: 36px;
    box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
    border: 1.5px solid;
    border-color: $content_blue;
    border-radius: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5;
    border: 0;
    color: #fff;
    background: $content_blue;
    font-family: 'Quicksand';
    margin-bottom: 24px;
  }
}