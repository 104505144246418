@import '../../../libs/styles/_colors';

.wrapper {
  width: 100vw;
  background: $content_blue;

  .line {
    height: 8px;
    background-color: $content_light_blue;
  }

  .container {
    display: flex;
    flex-direction: row;
    padding: 10px 100px;
    color: $content_blue;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;
    background-color: $content_blue;
    font-family: "Quicksand";

    .logo {
      height: 40px;
      width: 60px;
      text-align: center;
    }

    .rigthItems {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;

      .socialIcons {
        min-height: 45px;
        border-radius: 50px;
        background-color: #4472c4;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 600px;
        padding-top: 15px;


        .socialIcon {
          height: 30px;
          cursor: pointer;
        }
      }

      .copyright {
        font-size: 14px;
        margin-top: 20px;
        color: white;
      }
    }
  }
}