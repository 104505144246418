@import '../../../libs/styles/_colors';

.content {
  display: flex;
  font-family: 'Quicksand';
  align-items: center;
  justify-content: center;

  .id {
    display: flex;
    align-items: center;
    width: 40px;
    overflow: hidden;
    font-size: 14px;
    justify-content: center;
    min-height: 48px;
    margin-left: 24px
  }

  .nombre {
    @extend .id;
    justify-content: flex-start;
    width: 200px;
  }

  .valorMaximo {
    @extend .id;
    width: 90px;
  }

  .ponderacion {
    @extend .id;
    width: 90px;
  }

  .valor {
    @extend .id;
    width: 90px;
  }


  .inputContainer {
    @extend .id;
    width: 90px;


    .input {
      // @extend .id;
      border-radius: 8px;
      border: 0.5px solid;
      height: 24px;
      width: 60px;
      border-color: $content_blue;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      min-width: 100px;
      height: 24px;
      box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
      border: 1.5px solid;
      border-color: $content_blue;
      border-radius: 32px;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.5;
      border: 0;
      color: #fff;
      background: $content_blue;
      font-family: 'Quicksand';
    }

    .saveButton {
      color: $content_blue;
      background: white;
      border: 1px solid $content_blue;
    }
  }
}