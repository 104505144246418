@import '../../../../libs/styles/_colors';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  color: #131C41;
  max-width: 1200px;
  margin: auto;
  justify-content: space-between;
  font-family: Quicksand;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.5px;
    color: #131C41;
    margin-bottom: 32px;
  }

  .button {
    width: 280px;
    height: 48px;
    box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.16);
    border: 1.5px solid;
    border-color: $content_blue;
    border-radius: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5;
    border: 0;
    color: #fff;
    background: $content_blue;
    font-family: 'Quicksand';
    margin-top: 32px;
  }

  .content {
    width: 1200px;
    display: flex;
    justify-content: center;

    .options {
      margin-bottom: 24px;
      min-width: 300px;
    }

    .indicadores {
      min-width: 600px;
      margin-left: 32px;

    }
  }
}